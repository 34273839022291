import RenderComponents from "../../components/Elements/RenderComponents";
import { homeParams } from "../../util/constants";
import { ResponseData } from "../../util/types";
import useApiHook from "../../hooks/useApiHook";
import { Loader } from "../../components/Loader";
import ErrorPage from "../../components/Elements/ErrorPage";
import { useAppSelector } from "../../store/hook";
import { RootState } from "../../store";
import { useEffect, useMemo } from "react";
import Cc12NavBar from "../../components/Modules/Cc12NavBar";
import PageTitle from "../../components/Elements/PageTitle";

const Home: any = () => {
  const { locale } = useAppSelector((state: RootState) => state.site);

  const params = useMemo(() => {
    return { ...homeParams, locale: locale };
  }, [locale]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data, error, loading } = useApiHook<ResponseData>("GET", "api/home/", params);

  if (loading) return <Loader />;
  if (error) return <ErrorPage error={error} />;
  if (loading !== undefined && data === null) {
    return <ErrorPage error={{ message: " Data not Found " }} />;
  }
  return (
    <div>
      <PageTitle>
        <div className="img-wrapper">
          <img src="https://media.samsungepartners.com/common/images/samsung-logo.svg" className="cpcs_esis_home-title_logo" alt="SAMSUNG" />
        </div>
      </PageTitle>
      <Cc12NavBar />
      {data && data.attributes && data.attributes.home && <RenderComponents data={data.attributes.home} />}
    </div>
  );
};

export default Home;
