import { NavLink } from "react-router-dom";
import { MenuItem } from "../../../util/types";
import { FC } from "react";

interface NaVItemProps extends MenuItem {
  toggleDropdown: () => void;
}

const NavItem: FC<NaVItemProps> = ({ menuLabel, menuLink = "/", toggleDropdown }) => {
  return (
    <li className="cpcs-esis-nav-item" key={menuLabel}>
      <div>
        <NavLink to={menuLink} className="cpcs-esis-nav-link menu-link" onClick={() => toggleDropdown()}>
          <span>{menuLabel}</span>
        </NavLink>
      </div>
    </li>
  );
};

export default NavItem;
