import RenderComponents from "../../components/Elements/RenderComponents";
import { seriesParam } from "../../util/constants";
import { ResponseData } from "../../util/types";
import useApiHook from "../../hooks/useApiHook";
import { Loader } from "../../components/Loader";
import ErrorPage from "../../components/Elements/ErrorPage";
import { useAppSelector } from "../../store/hook";
import { RootState } from "../../store";
import { useEffect, useMemo } from "react";
import Cc12NavBar from "../../components/Modules/Cc12NavBar";
import PageTitle from "../../components/Elements/PageTitle";
import { useParams } from "react-router-dom";

const SeriesPage: any = () => {
  const { seriesSlug } = useParams();
  const { locale } = useAppSelector((state: RootState) => state.site);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [seriesSlug]);

  const params = useMemo(() => {
    return { ...seriesParam, locale: locale };
  }, [locale]);

  const { data, error, loading } = useApiHook<ResponseData>("GET", "api/all-series/" + seriesSlug, params, Boolean(seriesSlug));

  if (loading) return <Loader />;
  if (error) return <ErrorPage error={error} />;
  if (loading !== undefined && data === null) {
    return <ErrorPage error={{ message: " Data not Found " }} />;
  }
  return (
    <div>
      {data && data.attributes && data.attributes.pageName && (
        <PageTitle>
          <h2>{data.attributes.pageName}</h2>
        </PageTitle>
      )}
      <Cc12NavBar />
      {data && data.attributes && data.attributes.series && <RenderComponents data={data.attributes.series} />}
    </div>
  );
};

export default SeriesPage;
