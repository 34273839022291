/** @jsxImportSource @emotion/react */
import { FC, useMemo } from "react";
import { css, CSSObject } from "@emotion/react";
import { BannerProps } from "../../util/types";
import Button from "./Button";
import Image from "./Image";
import Description from "./Typography/Description";
import { getPCOverlayClass } from "../../util/getPcOverlayClass";
import { getMOverlayClass } from "../../util/getMoOverlayClass";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import parse from "html-react-parser";

const PositionStyles = (desktopTextPositionJson: CSSObject | null, mobileTextPositionJson: CSSObject | null) => css`
  @media (min-width: 768px) {
    [dir="ltr"] & {
      ${desktopTextPositionJson}
    }
    [dir="rtl"] & {
      ${desktopTextPositionJson}
    }
  }
  @media (max-width: 767px) {
    [dir="ltr"] & {
      ${mobileTextPositionJson}
    }
    [dir="rtl"] & {
      ${mobileTextPositionJson}
    }
  }
`;

const SmallBannerProductBanner: FC<BannerProps> = ({
  title,
  textColor,
  description,
  descriptionSize,
  positionPcCss,
  positionMoCss,
  bannerAltText,
  bannerMoImageUrl,
  bannerPcImageUrl,
  mobileTextPositionJson,
  desktopTextPositionJson,
  ctaColor,
  ctaText,
  ctaVariant,
  ctaURL,
}) => {
  const locale = useSelector((state: RootState) => state.site.locale);

  const pcStyles = useMemo(() => {
    return getPCOverlayClass(positionPcCss);
  }, [positionPcCss]);

  const moStyles = useMemo(() => {
    return getMOverlayClass(positionMoCss);
  }, [positionMoCss]);

  return (
    <div className="cpcs-esis_custom-banner">
      <div className="cpcs-esis_custom-banner-bg">
        <Image desktopSrc={bannerPcImageUrl} mobileSrc={bannerMoImageUrl} alt={bannerAltText} />
      </div>
      <div
        className={`cpcs-esis_custom-banner-content-area ${pcStyles} ${moStyles}`}
        css={PositionStyles(desktopTextPositionJson, mobileTextPositionJson)}
        dir={locale === "ar" ? "rtl" : "ltr"}
      >
        <div className="cpcs-esis_custom-banner-content-inner">
          {title && (
            <h5 className={"cpcs-esis_home-kv-carousel-content-title"} style={{ color: textColor }}>
              {parse(title)}
            </h5>
          )}
          <Description className="cpcs-esis_custom-banner-content-desc" description={description} size={descriptionSize} color={textColor} />
          <div className="cpcs-esis_overlay-content-btns">
            <div className="cpcs-esis_overlay-content-btn-item">
              <Button className="cta-heavy" to={ctaURL} color={ctaColor} variant={ctaVariant}>
                {ctaText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmallBannerProductBanner;
