

export const getMOverlayClass = (clsName: string | null) => {
  if (clsName === null) return ""
  switch (clsName) {
    case "TopCenter":
      return "cpcs-esis_overlay-top-center-mo";
    case "MiddleCenter":
      return "cpcs-esis_overlay-middle-center-mo";
    case "BottomCenter":
      return "cpcs-esis_overlay-bottom-center-mo";
    default:
      return ""
  }
}