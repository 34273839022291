import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

import site from "./site";
import feed from "./feed";

const rootReducer = combineReducers({
  site,
  feed,
});
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
