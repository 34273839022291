import { createSlice } from "@reduxjs/toolkit";
import { MenuItem, FooterProps } from "../util/types";

interface InitialState {
  locale: string | null;
  categoryFooter: null | FooterProps;
  navigationData: null | MenuItem[];
}
const slice = createSlice({
  name: "site",
  initialState: {
    locale: null,
    categoryFooter: null,
    navigationData: null,
  } as InitialState,
  reducers: {
    setMasterLocale: (state: InitialState, action: { type: string; payload: string }) => {
      state.locale = action.payload;
    },
    setHeader: (state, action) => {
      state.navigationData = action.payload;
    },
    setFooter: (state, action) => {
      state.categoryFooter = action.payload;
    },
  },
});

export default slice.reducer;
export const { setMasterLocale, setHeader, setFooter } = slice.actions;
