import { FC } from "react";
import parse from 'html-react-parser';
import { IntroHeaderProps } from '../../util/types';
import Section from '../Elements/Section';

interface Cc02IntroHeaderProps extends IntroHeaderProps {
  key: string;
 }
const Cc02IntroHeader: FC<Cc02IntroHeaderProps> = ({ title, description}) => {
  return (
    <Section id="intro-header" className="cpcs-esis-intro">
      <div className="cpcs-esis_intro-header-title">
        <h2>{parse(title)}</h2>
      </div>
      <div className="cpcs-esis_intro-header-desc">
        <p>{parse(description)}</p>
      </div>
    </Section>
  );
}

export default Cc02IntroHeader;