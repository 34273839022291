import { FC } from "react";
import Image from "./Image";
import Title from "./Typography/Title";
import Description from "./Typography/Description";
import { FeatureStorySquareItemProps } from "../../util/types";

const FeatureStorySquareItem: FC<FeatureStorySquareItemProps> = ({ description, eyebrowText, eyebrowTextSize, storyImgUrl, title, titleSize }) => {
  return (
    <div className="cpcs-esis-story-square-item">
      <div className="cpcs-esis-story-square-item-inner">
        <div className="cpcs-esis-story-square-item-img">
          <Image src={storyImgUrl} alt={title} />
        </div>
        <div className="cpcs-esis-story-square-item-content">
          {eyebrowText && (
            <div className="cpcs-esis-story-square-item-eyebrow-text">
              <strong>
                <p>{eyebrowText}</p>
              </strong>
            </div>
          )}
          <div className="cpcs-esis-story-square-item-title">
            <Title heading={title} size={"medium"} />
          </div>
          <div className="cpcs-esis-story-square-item-description">
            <Description description={description} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureStorySquareItem;
