import * as React from "react";

import { RootState } from "../../store";
import { useAppSelector } from "../../store/hook";

import Button from "./Button";
import Image from "./Image";
import ProductTitle from "./Typography/ProductTitle";
import languageCurrencySwitch from "../../util/languageCurrencySwitch";

interface ProductItemProps {
  productImgUrl: string;
  productTitle: string;
  ctaText: string;
  ctaURL: string;
  titleSize: "large" | "medium" | "small";
  price?: string | null;
  salePrice?: string | null;
  savings?: string;
}

const ProductItem: React.FC<ProductItemProps> = ({ productImgUrl, productTitle, ctaText, ctaURL, titleSize, price, salePrice, savings }) => {
  const { locale } = useAppSelector((state: RootState) => state.site);
  const difference = React.useMemo(() => {
    if (savings) {
      const amount = languageCurrencySwitch(savings, locale);
      if (locale && locale === "ar") return `وفّر ${amount}`;
      return `Save ${amount}`;
    }
    return;
  }, [locale, savings]);

  const sellingPrice = React.useMemo(() => {
    if (salePrice && price) return salePrice;
    return price;
  }, [price, salePrice]);
  return (
    <div className="cpcs-esis_featured-collection-item cpcs-esis_best-product-item">
      <div className="cpcs-esis_featured-collection-item-inner">
        <div className="cpcs-esis_featured-collection-item-img">
          <Image src={productImgUrl} alt={productTitle} />
        </div>
        <div className={`cpcs-esis_featured-collection-item-title ${productTitle.length > 15 && "block-lg"}`}>
          <ProductTitle title={productTitle} size={titleSize} className="cpcs-esis_featured-collection-item-title-inner" />
        </div>
        <div className="cpcs-esis_featured-collection-item-price-container">
          <p className="cpcs-esis_featured-collection-item-sale-price">{sellingPrice}</p>
          {salePrice && price && (
            <div className="cpcs-esis_featured-collection-item-actual-price-diff">
              <span className="cpcs-esis_featured-collection-item-actual-price">{price}</span> {difference}
            </div>
          )}
        </div>
        <div className="cpcs-esis_featured-collection-item-cta">
          <div className="cpcs-esis_featured-collection-item-cta-inner">
            <Button to={ctaURL} color="black" variant="contained">
              {ctaText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
