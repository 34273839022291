import { FC } from "react";
import { Link } from "react-router-dom";
import { categoryFooter, FooterProps, Subcategory } from "../../util/types";
import Section from "../Elements/Section";
import Title from "../Elements/Typography/Title";

const CatagoriesFooterSubItem = ({ label, footerUrl }: Pick<Subcategory, 'label' | 'footerUrl'>) => {
  return (
    <ul>
      <li>
        <div className="cpcs-esis-catagories-footer-subitem">
          <div className="cpcs-esis-catagories-footer-subitem-title">
            <Link to={footerUrl} className="cta cta--link cta--black">
              <p>{label}</p>
            </Link>
          </div>
        </div>
      </li>
    </ul>
  )
}


const CatagoriesFooterMasterItem = ({sectionId, headline,footerSubNav}: categoryFooter) => {
  return (
    <div className="cpcs-esis_catagories-footer-item" id={sectionId}>
      <div className="cpcs-esis_catagories-footer-item-inner">
        <div className="cpcs-esis-catagories-footer-item-title">
          <p>{headline}</p>
        </div>
        <div className="cpcs-esis-catagories-footer-links">
          {footerSubNav&&footerSubNav.map((cata) => (
            <CatagoriesFooterSubItem
              key={cata.id}
              label={cata.menuLabel}
              footerUrl={cata.menuLink}
            />
          ))}
        </div>
      </div>
    </div>

  )
}

const Cc05CatagoriesFooter:FC<FooterProps> = ({ headline,footer}) => {
  return (
    <Section className="bg-off-white">
      <Title className="cpcs-esis_catagories-footer-title" heading={headline} /> 
      <div className="cpcs-esis_catagories-footer-container">
        {footer && footer.map((cata) => (
          <CatagoriesFooterMasterItem {...cata} key={cata.id}/>
        ))}
      </div>
    </Section>
  )
}

export default Cc05CatagoriesFooter;