/** @jsxImportSource @emotion/react */
import { FC, useMemo } from "react";
import { css, CSSObject } from "@emotion/react";
import { HomeGeneralKv } from "../../util/types";
import Image from "../Elements/Image";
import Title from "../Elements/Typography/Title";
import Description from "../Elements/Typography/Description";
import { getPCOverlayClass } from "../../util/getPcOverlayClass";
import { getMOverlayClass } from "../../util/getMoOverlayClass";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const PositionStyles = (desktopTextPositionJson: CSSObject | null, mobileTextPositionJson: CSSObject | null) => css`
  @media (min-width: 768px) {
    [dir="ltr"] & {
      ${desktopTextPositionJson}
    }
    [dir="rtl"] & {
      ${desktopTextPositionJson}
    }
  }
  @media (max-width: 767px) {
    [dir="ltr"] & {
      ${mobileTextPositionJson}
    }
    [dir="rtl"] & {
      ${mobileTextPositionJson}
    }
  }
`;
const Cc10HomeGeneralKvs: FC<HomeGeneralKv> = (props) => {
  const {
    backgroundAltText,
    backgroundMoImageUrl,
    backgroundPcImageUrl,
    buttons,
    description,
    descriptionSize,
    desktopTextPositionJson,
    mobileTextPositionJson,
    positionMoCss,
    positionPcCss,
    sectionId,
    textColor,
    title,
    titleSize,
  } = props;
  const locale = useSelector((state: RootState) => state.site.locale);

  const pcStyles = useMemo(() => {
    return getPCOverlayClass(positionPcCss);
  }, [positionPcCss]);

  const moStyles = useMemo(() => {
    return getMOverlayClass(positionMoCss);
  }, [positionMoCss]);

  return (
    <div className="cpcs-esis-container bg-off-white" id={sectionId}>
      <div className="cpcs-esis-section">
        <div className="cpcs-esis_home-general-kv ">
          <div
            className={`cpcs-esis_kv-general-content-area ${pcStyles} ${moStyles}`}
            css={PositionStyles(desktopTextPositionJson, mobileTextPositionJson)}
            dir={locale === "ar" ? "rtl" : "ltr"}
          >
            <div className="cpcs-esis_kv-general-content-inner">
              <Title className="cpcs-esis_kv-general-content-title" heading={title} size={titleSize} color={textColor} />
              <Description className="cpcs-esis_kv-general-content-desc" description={description} size={descriptionSize} color={textColor} />
              <div className="cpcs-esis_overlay-content-btns">
                {buttons &&
                  buttons.map((btn, i) => (
                    <div className="cpcs-esis_overlay-content-btn-item" key={i}>
                      <Link to={btn.ctaURL} className={`cta cta--regularText cta--${btn.ctaColor} cta-heavy cta-general-kv`} title={btn.ctaText}>
                        <span>{btn.ctaText}</span>
                      </Link>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="cpcs-esis_kv-general-figure">
            <Image desktopSrc={backgroundPcImageUrl} mobileSrc={backgroundMoImageUrl} alt={backgroundAltText} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cc10HomeGeneralKvs;
