import axios, { AxiosResponse } from "axios";
import { headers } from "./authToken";
import { redirect } from 'react-router-dom';

const BAD_REQUEST = "Bad Request";

const apiRequest = axios.create({
  baseURL: process.env.REACT_APP_CMS_API_DOMAIN ,
  headers: headers,
});

class ServerError extends Error {
  constructor() {
    super("Server Error");
    this.name = "ServerError";
  }
}

class NetworkError extends Error {
  constructor() {
    super("Network Error");
    this.name = "NetworkError";
  }
}

type ErrorResponse = {
  status: number;
  name: string;
  message: string;
  details: {} | unknown;
};

type ResponseProps<T> = {
  data: T | null;
  error: ErrorResponse;
};

const api = async <T>(method: string, url: string, params: Record<string, any> = {}): Promise<T> => {
  let responseData: ResponseProps<T>;
  try {
    responseData = await apiRequest({
      method,
      url,
      data: method === "POST" ? params : undefined,
      params: method === "GET" ? params : undefined,
    });
  } catch (error) {
    const { response } = error as { response: AxiosResponse | undefined };
    if (response !== undefined) {
      if (response.status === 404) {
        redirect("/error");
      }
      if (response.status >= 400 && response.status < 500) {
        const { status, data } = response;
        const message = data.message || BAD_REQUEST;
        return Promise.reject({ status, message });
      }
      if (response.status >= 500) {
        throw new ServerError();
      }
      throw new NetworkError();
    }
    redirect("/error");
    throw new NetworkError();
  }
  if (responseData) {
    const { data, error } = responseData;
    if (error || (axios.isAxiosError(error))) {
      const msg = error.message || BAD_REQUEST;
      return Promise.reject({ error, msg });
    }
    if (data !== null) {
      return data;
    }
  }
  return null as T;
};

export default api;