/** @jsxImportSource @emotion/react */
import { FC, useEffect, useMemo, useState } from "react";
import { css } from "@emotion/react";
import { LargeTilePosition, ProductCard, ShowCaseLargeCards } from "../../util/types";
import ShowCaseCard from "../Elements/HomeShowCaseCard";

const FlexBox = (flexDirection: string, isMobile: boolean | null) => css`
  width: 100%;
  height: 638px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 1;
  gap: 0;

  @media (min-width: 1025px) and (max-width: 1440px) {
    height: 44.30556vw;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    height: 44.30556vw;
  }
  @media (max-width: 767px) {
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
const FlexItem = (flex: string, cardSize: "large" | "normal" | undefined) => css`
  flex: ${flex};
  width: ${cardSize && cardSize === "large" ? "50%" : "25%"};
  height: ${cardSize && cardSize === "large" ? "100%" : "50%"};
  @media (max-width: 767px) {
    flex: ${cardSize && cardSize === "large" ? "100%" : "50%"};
    width: ${cardSize && cardSize === "large" ? "100%" : "50%"};
    // height: ${cardSize && cardSize === "large" ? "110.1111111vw" : "63.88888889vw"};
    height: auto;
  }
`;
const getFlexItem = (index: number, position: LargeTilePosition, isMobile: Boolean) => {
  if (isMobile && index === 0) return FlexItem("1 1 50%", "large");
  if (isMobile && index !== 0) return FlexItem("1 1 25%", "normal");
  if ((position === "left" && index === 0) || (position === "right" && index === 4)) {
    return FlexItem("1 1 50%", "large");
  }
  return FlexItem("1 1 25%", "normal");
};
const findClass = (index: number, position: LargeTilePosition, isMobile: Boolean) => {
  if (isMobile && index === 0) return "cpcs-esis_show-case-card-item-large";
  if (isMobile && index !== 0) return "cpcs-esis_show-case-card-item-small";
  if ((position === "left" && index === 0) || (position === "right" && index === 4)) {
    return "cpcs-esis_show-case-card-item-large";
  }
  return "cpcs-esis_show-case-card-item-small";
};

const Cc11ShowCaseLargeCards: FC<ShowCaseLargeCards> = (props) => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 767);

  function handleWindowSizeChange() {
    if (window.innerWidth <= 767) {
      return setIsMobile(true);
    }
    return setIsMobile(false);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const productList = useMemo(() => {
    let largeItem: ProductCard = {
      id: props.id,
      productImgUrl: props.productImgUrl,
      productTitle: props.productTitle,
      titleSize: props.titleSize,
      ctaText: props.ctaText,
      ctaVariant: props.ctaVariant,
      ctaBgColor: props.ctaColor,
      ctaURL: props.ctaURL,
      ctaColor: "black",
      ctaIcon: false,
      ctaUnderlined: false,
    };
    if (props.largeTilePosition === "left" || isMobile) {
      return [largeItem].concat(props.productCard);
    }
    return props.productCard.concat(largeItem);
  }, [props, isMobile]);

  return (
    <div className="cpcs-esis-container bg-off-white">
      <div className="cpcs-esis-section">
        <div css={FlexBox(props.largeTilePosition, isMobile)}>
          {productList.map((item, i: number) => (
            <div css={getFlexItem(i, props.largeTilePosition, isMobile)} key={i}>
              <div className={`cpcs-esis_show-case-card-item ${findClass(i, props.largeTilePosition, isMobile)}`}>
                <ShowCaseCard {...item} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Cc11ShowCaseLargeCards;
