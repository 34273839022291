import { ProductListingProps } from "./types";

// Masood Sort
const productSort = (data: ProductListingProps[]) => {
  const flagged_data = [];
  const flagged_ids: { [key: string]: boolean } = {};
  data.map((itm) => {
    if (itm["isRecommended"]) {
      flagged_data.push(itm);
      return (flagged_ids[itm["id"]] = true);
    }
    return flagged_data.push(null);
  });

  const sorted_data = data.sort((a, b) => Number(b["discount"]) - Number(a["discount"]));
  let j = 0;
  let i = 0;
  while (i < sorted_data.length) {
    if (sorted_data[i]["id"] in flagged_ids) {
      i++;
      continue;
    }
    if (flagged_data[j] !== null) {
      j++;
      continue;
    }
    flagged_data[j] = sorted_data[i];
    i++;
    j++;
  }

  return flagged_data;
};

export default productSort;
