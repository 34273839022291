import { createHashRouter } from "react-router-dom";
import MainLayout from "./components/Layouts/MainLayout";
import ErrorPage from "./components/Elements/ErrorPage";
import Home from "./views/Home";
import CategoryPage from "./views/Category";
import GroupCategoryPage from "./views/GroupCategory";
import SeriesPage from "./views/Series";

const routes: any = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        element: <Home />,
        index: true,
      },
      {
        path: "category/:categorySlug",
        element: <CategoryPage />,
      },
      {
        path: "gc/:groupCategorySlug",
        element: <GroupCategoryPage />,
      },
      {
        path: "series/:seriesSlug",
        element: <SeriesPage />,
      },
      {
        path: "error",
        element: <ErrorPage />,
      },
    ],
  },
];

const routers = createHashRouter(routes);

export default routers;
