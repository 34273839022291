import { Link } from "react-router-dom";
import { CatagoriesMaster, GroupCategoryList } from "../../util/types";
import Section from "../Elements/Section";
import Image from "../Elements/Image";

const CatagoriesMasterItem = ({
  label,
  iconImgUrl,
  iconAltText,
  iconCtaUrl,
}: Pick<GroupCategoryList, "label" | "iconImgUrl" | "iconAltText" | "iconCtaUrl">) => {
  return (
    <div className="cpcs-esis_catagories-mst-item">
      <Link to={iconCtaUrl} className="cpcs-esis_catagories-mst-item-inner">
        <div className="cpcs-esis_catagories-mst-item-icon">
          <div className="icon-wrapper">
            <Image src={iconImgUrl} alt={iconAltText} />
          </div>
        </div>
        <div className="cpcs-esis_catagories-mst-item-label">
          <div className="cpcs-esis_catagories-mst-item-label-inner">{label}</div>
        </div>
      </Link>
    </div>
  );
};

const Cc03CatagoriesMaster: React.FC<CatagoriesMaster> = ({ headline, categoryList }) => {
  return (
    <Section>
      {/* <Title className="cpcs-esis_catagories-mst-title" heading={headline} /> */}
      <div className="cpcs-esis_catagories-mst-list cpcs-esis-container">
        <div className="cpcs-esis_catagories-mst-list-inner">
          {categoryList &&
            categoryList.length > 0 &&
            categoryList.map((cata, index) => (
              <CatagoriesMasterItem key={index} label={cata.label} iconImgUrl={cata.iconImgUrl} iconAltText={cata.iconAltText} iconCtaUrl={cata.iconCtaUrl} />
            ))}
        </div>
      </div>
    </Section>
  );
};

export default Cc03CatagoriesMaster;
