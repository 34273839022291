import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";

import store from "./store";
import "./assets/css/base.css";
import "./assets/css/site.css";
import "./assets/css/swiper.css";
import "./assets/css/components/cc01_mainKvs.css";
import "./assets/css/components/cc02_intro-header.css";
import "./assets/css/components/cc03_catagories-master.css";
import "./assets/css/components/cc04_shop-by-selector.css";
import "./assets/css/components/cc05_catagories-footer.css";
import "./assets/css/components/cc06_featured-collection.css";
import "./assets/css/components/cc07_story-telling.css";
import "./assets/css/components/cc08_buy-together.css";
import "./assets/css/components/cc09_review-section.css";
import "./assets/css/components/cc10_home_general_kvs.css";
import "./assets/css/components/cc11_home_show_case_card.css";
import "./assets/css/components/cc13_section_title.css";
import "./assets/css/components/cc14_banner_product.css";
import "./assets/css/components/cc15_banner-category-series.css";
import "./assets/css/components/cc19_feature_story_square.css";
import "./assets/css/components/cc20_black-space.css";
import "./assets/css/components/banner.css";
import "./assets/css/overlay-position.css";
import "./assets/css/button.css";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
