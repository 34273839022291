import { FC } from "react";
import { MainKvsProps } from "../../util/types";
import HeroKv from "../Elements/HeroKv";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/grid";

const Cc01MainKvs: FC<MainKvsProps> = ({ kvs }) => {
  return (
    <div className="cpcs-esis_home-kv-carousel-wrapper bg-white" id="home-banners">
      <div className="section-inner ">
        <div className="cpcs-esis_home-kv-carousel">
          <Swiper
            modules={[Navigation, Pagination]}
            slidesPerView={1}
            spaceBetween={1}
            navigation={true}
            pagination={{ clickable: true }}
            className="cpcs-esis_mainkv_swiper"
          >
            {kvs &&
              kvs.map((banner, i) => (
                <SwiperSlide key={banner.id} virtualIndex={i}>
                  <HeroKv {...banner} key={banner.id} />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Cc01MainKvs;
