const priceFormatter = (price?: string | null) => {
  if (price) {
    let parts = price.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  return "";
};

export default priceFormatter;
