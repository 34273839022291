import { extractNumber } from "./extractNumber";
import priceFormatter from "./priceFormatter";

const languageCurrencySwitch = (price: string, locale: string | null) => {
  if (price) {
    let price_only = extractNumber(price);
    let formattedPrice = priceFormatter(`${price_only}`);
    if (locale && locale === "ar") return `${formattedPrice} د.إ`;
    if (price && locale && !price.includes("AED")) return `${formattedPrice} AED`;
  }
  return priceFormatter(price);
};

export default languageCurrencySwitch;
