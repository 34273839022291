/** @jsxImportSource @emotion/react */
import { FC, useMemo } from "react";
import { CSSObject, css } from "@emotion/react";
import { MainKvBannerProps } from "../../util/types";
import Button from "./Button";
import Image from "./Image";
import Title from "./Typography/Title";
import Description from "./Typography/Description";
import { getPCOverlayClass } from "../../util/getPcOverlayClass";
import { getMOverlayClass } from "../../util/getMoOverlayClass";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const PositionStyles = (desktopTextPositionJson: CSSObject | null, mobileTextPositionJson: CSSObject | null) => css`
  @media (min-width: 768px) {
    [dir="ltr"] & {
      ${desktopTextPositionJson}
    }
    [dir="rtl"] & {
      ${desktopTextPositionJson}
    }
  }
  @media (max-width: 767px) {
    [dir="ltr"] & {
      ${mobileTextPositionJson}
    }
    [dir="rtl"] & {
      ${mobileTextPositionJson}
    }
  }
`;

const HeroKv: FC<MainKvBannerProps> = (props) => {
  const {
    bannerAltText,
    bannerMoImageUrl,
    bannerPcImageUrl,
    description,
    descriptionSize,
    desktopTextPositionJson,
    id,
    mobileTextPositionJson,
    positionMoCss,
    positionPcCss,
    subTitle,
    textColor,
    title,
    titleSize,
    ctaColor,
    ctaText,
    ctaURL,
    ctaVariant,
  } = props;

  const locale = useSelector((state: RootState) => state.site.locale);

  const pcStyles = useMemo(() => {
    return getPCOverlayClass(positionPcCss);
  }, [positionPcCss]);
  const moStyles = useMemo(() => {
    return getMOverlayClass(positionMoCss);
  }, [positionMoCss]);

  return (
    <div className={`cpcs-esis_home-kv-carousel-item  text-${textColor}`} key={id}>
      <div className={`cpcs-esis_home-kv-carousel-content-area ${pcStyles} ${moStyles}`} css={PositionStyles(desktopTextPositionJson, mobileTextPositionJson)} dir={locale === "ar" ? "rtl" : "ltr"}>
        <div className="cpcs-esis_home-kv-carousel-content-inner">
          <Title className="cpcs-esis_home-kv-carousel-content-title" heading={title} size={titleSize} color={textColor} />
          {subTitle !== null && <Title className="cpcs-esis_home-kv-carousel-content-sub-title" heading={subTitle} size={"small"} color={textColor} />}
          <Description className={"cpcs-esis_home-kv-carousel-content-desc"} description={description} size={descriptionSize} color={textColor} />
          <div className="cpcs-esis_home-kv-carousel-content-ctas">
            <Button to={ctaURL} variant={ctaVariant} color={ctaColor}>
              {ctaText}
            </Button>
          </div>
        </div>
      </div>
      <div className="cpcs-esis_home-kv-carousel-figure">{bannerPcImageUrl !== null && bannerMoImageUrl !== null && <Image desktopSrc={bannerPcImageUrl} mobileSrc={bannerMoImageUrl} alt={bannerAltText} />}</div>
    </div>
  );
};
export default HeroKv;
