import { FC } from "react";
import { ShowCaseProductCards } from "../../util/types";
import ShowCaseCard from "../Elements/HomeShowCaseCard";

const Cc16ShowCaseProductCards: FC<ShowCaseProductCards> = ({
  productCard,
}) => {
  return (
    <div className="cpcs-esis-container bg-off-white">
      <div className="cpcs-esis-section">
        <div className="cpcs-esis-product-card-container">
          {productCard.map((item) => (
            <div key={item.id} className="cpcs-esis-product-card-inner">
              <div
                className={`cpcs-esis_show-case-card-item cpcs-esis_show-case-card-item-small`}
              >
                <ShowCaseCard {...item} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Cc16ShowCaseProductCards;
