import { FC } from "react";
import { ProductCard } from "../../util/types";
import Button from "./Button";
import Image from "./Image";
import ProductTitle from "./Typography/ProductTitle";

const ShowCaseCard: FC<ProductCard> = ({ productImgUrl, productTitle, titleSize, ctaText, ctaURL, ctaColor, ctaVariant }) => {
  return (
    <div className="cpcs-esis-sm-card">
      <div className="cpcs-esis-sm-card-inner">
        <div className="cpcs-esis-sm-card-img-container">
          <Image src={productImgUrl} alt={productTitle} />
        </div>
        <div className="cpcs-esis-sm-card-title-container">
          <ProductTitle title={productTitle} size={titleSize} className="cpcs-esis_featured-collection-item-title-inner" />
        </div>
        <div className="cpcs-esis-sm-card-btn">
          <div className="cpcs-esis-sm-card-btn-inner">
            <Button title={ctaText} to={ctaURL} color={ctaColor} variant={ctaVariant === undefined ? "underlined" : ctaVariant}>
              {ctaText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowCaseCard;
