export const ARLangs = ["ar", "ae_ar"];
export const ENLangs = ["sa_en"];

export const modules = {
  INTRO_SECTION: "feature.ft-02-intro-header",
  KV_CAROUSEL: "feature.ft-01-kv-carousel",
  FEATURED_COLLECTIONS: "feature.ft-04-featured-collection",
  SHOP_BY_SELECTOR: "feature.ft-09-shop-selector",
  STORY_TELLING: "feature.ft-07-story-telling",
  BUNDLE_COLLECTION: "feature.ft-08-bundle-collection",
  REVIEW_SECTION: "details.dt-01-reviews",
  CATEGORIES_MASTER: "feature.ft-03-category-icons",
  HOME_CATEGORY_KV: "feature.ft-05-category-banner",
  SHOW_CASE_LARGE_CARDS: "showcase-card.sc-02-large-tile",
  SHOW_CASE_PRODUCT_CARDS: "showcase-card.sc-01-product-tile",
  SHOW_CASE_BANNER_CARDS: "showcase-card.sc-03-banner-tile",
  SECTION_TITLE: "core-category.c11-section-title",
  BANNER_PRODUCT: "feature.ft-13-banner-product",
  HALF_BANNER_TILES: "feature.ft-14-banner-category-series",
  FEATURE_STORY_SQUARE: "feature.ft-15-feature-story-square",
  BLANK_SPACE: "core-category.c13-blank-space",
};

export const categoryParams = {
  populate: "*",
  "populate[1]": "category.kvs",
  "populate[3]": "category.selectorListing",
  "populate[4]": "category.productListing",
  "populate[5]": "category.buttons",
  "populate[6]": "category.categoryList",
  "populate[7]": "category.storyTellingTiles",
};

export const gcParams = {
  "populate[1]": "groupCategory.kvs",
  "populate[2]": "groupCategory.productListing",
  "populate[3]": "groupCategory.productCard",
  "populate[4]": "groupCategory.categoryList",
  "populate[5]": "groupCategory.buttons",
  "populate[6]": "groupCategory.selectorListing",
  "populate[7]": "groupCategory.reviews",
  "populate[8]": "groupCategory.bannerSquare",
  "populate[9]": "groupCategory.bannerHalf",
};

export const homeParams = {
  "populate[1]": "home.kvs",
  "populate[2]": "home.buttons",
  "populate[3]": "home.productListing",
  "populate[4]": "home.productCard",
  "populate[5]": "home.categoryList",
  "populate[6]": "home.selectorListing",
  "populate[7]": "home.reviews",
  "populate[8]": "home.bannerRect",
  "populate[9]": "home.bannerHalf",
};

export const seriesParam = {
  "populate[0]": "*",
  "populate[1]": "series.kvs",
  "populate[2]": "series.storyTellingTiles",
  "populate[3]": "series.productListing",
};
export const navParam = {
  "populate[0]": "navigationData.menus",
};

export const footerParam = {
  "populate[categoryFooter][populate]": "footerSubNav",
};

export const DATA_NOT_FOUND = "Data not found";
