import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import routers from "./Routers";
import { findLanguage } from "./util/findLanguage";
import { useDispatch } from "react-redux";
import { setMasterLocale } from "./store/site";
import parse_xml from "./util/parse-xml";
import { setFeed } from "./store/feed";

const feedUrl = process.env.REACT_APP_XML_LINK;
const url = window.location.href;
const locale = findLanguage(url);

const App: any = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const items = await parse_xml(feedUrl);
      dispatch(setFeed(items));
    })();
  }, [dispatch]);

  useEffect(() => {
    dispatch(setMasterLocale(locale));
    document.documentElement.dir = locale === "ar" ? "rtl" : "ltr";
  }, [dispatch]);

  return <RouterProvider router={routers} />;
};

export default App;
