import React, { FC } from "react";
import Title from "../Elements/Typography/Title";
import { Pagination, Grid } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import { Cc19FeatureStorySquareProps } from "../../util/types";
import FeatureStorySquareItem from "../Elements/FeatureStorySquareItem";

const Cc19FeatureStorySquare: FC<Cc19FeatureStorySquareProps> = ({ storyTellingTiles }) => {
  return (
    <div className="cpcs-esis-container">
      <div className="cpcs-esis-story-square-wrapper">
        <Title />
        <div className="cpcs-esis-story-square-inner">
          {storyTellingTiles && (
            <Swiper
              modules={[Grid, Pagination]}
              slidesPerView={2}
              spaceBetween={1}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 1,
                  grid: { rows: 1 },
                },
                767: {
                  slidesPerView: 1,
                  spaceBetween: 1,
                  grid: { rows: 1 },
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 1,
                  grid: { rows: 3 },
                },
              }}
              pagination={{
                clickable: true,
              }}
              className="cpcs-esis_story-square_swiper"
            >
              {storyTellingTiles.map((tile, i) => (
                <SwiperSlide key={tile.id} virtualIndex={i}>
                  <FeatureStorySquareItem {...tile} />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cc19FeatureStorySquare;
