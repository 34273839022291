import * as React from "react";

interface ImageProps {
  src?: string | null;
  desktopSrc?: string | null;
  mobileSrc?: string | null;
  alt?: string | null;
}

const Image: React.FC<ImageProps> = ({ src, desktopSrc, mobileSrc, alt = "esis-image" }) => {
  let altText = alt === null ? "esis-image" : alt;
  if (desktopSrc || mobileSrc) {
    return (
      <div className="img-wrapper">
        <img className="desktop-only" src={desktopSrc ? desktopSrc : ""} alt={altText} />
        <img className="mobile-only" src={mobileSrc ? mobileSrc : ""} alt={altText} />
      </div>
    );
  }
  return (
    <div className="img-wrapper">
      <img src={src ? src : ""} alt={altText} />
    </div>
  );
};

export default Image;
