/** @jsxImportSource @emotion/react */
import { FC } from "react";
import { BannerTilePosition, ShowCaseBannerCards } from "../../util/types";
import ShowCaseCard from "../Elements/HomeShowCaseCard";
import Banner from "../Elements/Banner";
import { css } from "@emotion/react";
const FlexBox = (flexDirection: BannerTilePosition) => css`
  width: 100%;
  height: 638px;
  display: flex;
  flex-direction: ${flexDirection === "TopLeft" || flexDirection === "BottomRight" ? "row" : "row-reverse"};
  flex-wrap: wrap;
  flex-grow: 1;
  gap: 0;

  @media (min-width: 1025px) and (max-width: 1440px) {
    height: 44.30556vw;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    height: 44.30556vw;
  }
  @media (max-width: 767px) {
    height: auto;
    flex-direction: row;
  }
`;
const BannerCard = (props: ShowCaseBannerCards) => {
  return (
    <div className="cpcs-esis-banner-card-wrapper">
      <div className="cpcs-esis-banner-card-inner">
        <Banner {...props} />
      </div>
    </div>
  );
};

const Cc17ShowCaseBannerCards: FC<ShowCaseBannerCards> = (props) => {
  return (
    <div className="cpcs-esis-container bg-off-white">
      <div className="cpcs-esis-section">
        <div css={FlexBox(props.bannerTilePosition)}>
          {props.bannerTilePosition === "TopLeft" || props.bannerTilePosition === "TopRight" ? <BannerCard {...props} /> : null}
          {props.productCard &&
            props.productCard.map((item) => (
              <div key={item.id} className="cpcs-esis-product-card-inner">
                <div className={`cpcs-esis_show-case-card-item cpcs-esis_show-case-card-item-small`}>
                  <ShowCaseCard {...item} />
                </div>
              </div>
            ))}
          {props.bannerTilePosition === "BottomLeft" || props.bannerTilePosition === "BottomRight" ? <BannerCard {...props} /> : null}
        </div>
      </div>
    </div>
  );
};

export default Cc17ShowCaseBannerCards;
