import { FC } from "react";
import Plus from "../Icons/Plus";
import Minus from "../Icons/Minus";
import { NavLink } from "react-router-dom";

interface DropDownItemProps {
  label: string;
  link: string | undefined;
  onClick: (label: string) => void;
}

export const DropDownItem: FC<DropDownItemProps> = ({ label, link = "/", onClick }) => {
  return (
    <NavLink to={link} className="cpcs-esis-dropdown-item" onClick={() => onClick("")}>
      <span>{label}</span>
    </NavLink>
  );
};

interface NavDropDownProps {
  dropdown: { label: string; open: boolean };
  onClick: (label: string) => void;
  label: string;
  children: JSX.Element | React.ReactNode;
}

export const NavDropDown: FC<NavDropDownProps> = ({ dropdown, onClick, label, children }) => {
  return (
    <li className="cpcs-esis-nav-item cpcs-esis-dropdown dropdown">
      <div
        className={`cpcs-esis-nav-link cpcs-esis-dropdown-toggle ${dropdown.label === label && dropdown.open ? "active" : ``}`}
        onClick={() => onClick(label)}
        id="navbarDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span className="cpcs-esis-dropdown-label" onClick={() => onClick(label)}>
          {label}
        </span>
        <span className="cpcs-esis-dropdown-btn-icon">{dropdown.label === label && dropdown.open ? <Minus /> : <Plus />}</span>
      </div>

      <div className={`cpcs-esis-dropdown-menu ${dropdown.label === label && dropdown.open && "show active"}`} aria-labelledby="navbarDropdown">
        {children}
      </div>
    </li>
  );
};
