import { FC } from "react";

interface SectionProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  children: JSX.Element| React.ReactNode
}

const Section: FC<SectionProps> = ({ className="", children, ...rest }) => {
  return (
    <div className={`cpcs-esis-section-wrapper ${className}`} {...rest}>
      <div className="cpcs-esis-section">
        <div className="cpcs-esis-section-inner">
          {children}
        </div>
      </div>
    </div>
  );
}

export default Section;