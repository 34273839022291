import { FC } from "react";
import parse from 'html-react-parser';
import { Rating } from 'react-simple-star-rating'
import Section from "../Elements/Section";
import { Reviews, TopReviews } from "../../util/types";
import Image from "../Elements/Image";
import Title from "../Elements/Typography/Title";




const Review: FC<Reviews> = ({ brandLogoUrl, rating, productName, reviewQuote, author, brandAltText}) => {
  return (
    <div className="cpcs-esis_key-reviews-item">
      <div className="cpcs-esis_key-reviews-item-inner">
        <div className="cpcs-esis_key-reviews-item-logo">
          <Image src={brandLogoUrl} alt={brandAltText} />
        </div>
        <div className="cpcs-esis_key-reviews-item-rating">
          <div className="img-wrapper">
            <Rating initialValue={rating} size={18} readonly />
          </div>
        </div>
        {productName !== null && <div className="cpcs-esis_key-reviews-item-product">
          <p className="text-bold">{parse(productName)}</p>
        </div>}
        <div className="cpcs-esis_key-reviews-item-quote">
          <p className="text-quote">{reviewQuote && parse(reviewQuote)}</p>
        </div>
        {author !== null && <div className="cpcs-esis_key-reviews-item-author">
          <p className="text-bold">{parse(author)}</p>
        </div>}
      </div>
    </div>
  );
}



const Cc09ReviewSection: FC<TopReviews> = ({headline, reviews}) => {
  return (
    <Section id="top-reviews">
      <Title className="cpcs-esis_key-reviews-title" size="large" heading={headline} />
      <div className="cpcs-esis_key-reviews-container">
        <div className="cpcs-esis_key-reviews-block">
          {reviews&&reviews.map((review: Reviews, i: number) => <Review {...review} key={i} />)}
        </div>
      </div>
    </Section>
  );
}

export default Cc09ReviewSection;