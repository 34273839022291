/** @jsxImportSource @emotion/react */
import { FC, useMemo } from "react";
import parse from "html-react-parser";
import { css } from "@emotion/react";

const Styles = (color: string) => css`
  color: ${color};
`;
interface DescriptionProps extends React.HTMLAttributes<HTMLDivElement> {
  description?: string | null;
  size?: "large" | "medium" | "small" | null;
  color?: string;
}

const Description: FC<DescriptionProps> = ({ description, size = "medium", color = "black", ...rest }) => {
  const customclasses: string = useMemo(() => {
    if (size === "large") return `text-lg`;
    if (size === "small") return `text-sm`;
    return "";
  }, [size]);

  if (description && description !== null) {
    return (
      <div {...rest}>
        <p className={customclasses} css={Styles(color)}>
          {parse(description)}
        </p>
      </div>
    );
  }
  return <></>;
};

export default Description;
