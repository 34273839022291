import * as React from "react";
import { Navigation, Grid } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { ProductListingProps } from "../../util/types";
import ProductItem from "./ProductItem";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/grid";

interface ProductListingBlockProps {
  products: ProductListingProps[];
}

const SwiperComponent: React.FC<ProductListingBlockProps> = ({ products }) => {
  return (
    <Swiper
      modules={[Grid, Navigation]}
      slidesPerView={2}
      spaceBetween={1}
      breakpoints={{
        200: {
          slidesPerView: 2,
          spaceBetween: 1,
          grid: { rows: 2 },
        },
        320: {
          slidesPerView: 2,
          spaceBetween: 1,
          grid: { rows: 2 },
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 1,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 1,
        },
      }}
      navigation={true}
      className="cpcs-esis_product_listing_swiper"
    >
      {products.map((product, i) => (
        <SwiperSlide key={product.id} virtualIndex={i}>
          <ProductItem
            key={product.id}
            productImgUrl={product.productImgUrl}
            productTitle={product.productTitle}
            ctaText={product.ctaText}
            titleSize={product.titleSize}
            ctaURL={product.ctaURL}
            price={product.price}
            salePrice={product.salePrice}
            savings={product.savings}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

const ProductListingBlock: React.FC<ProductListingBlockProps> = ({ products }) => {
  return (
    <div className="cpcs-esis_featured-collection-listing">
      <div
        className={`cpcs-esis_featured-collection-listing-inner ${products && products.length < 4 && "cpcs-esis_featured-collection-listing-inner-smaller"}`}
      >
        {products && products.length < 4 ? (
          products.map((product, i) => (
            <ProductItem
              key={i}
              productImgUrl={product.productImgUrl}
              productTitle={product.productTitle}
              ctaText={product.ctaText}
              ctaURL={product.ctaURL}
              titleSize={product.titleSize}
              price={product.price}
              salePrice={product.salePrice}
              savings={product.savings}
            />
          ))
        ) : (
          <SwiperComponent products={products} />
        )}
      </div>
    </div>
  );
};

export default ProductListingBlock;
