import { FC } from "react";
import { FeaturedCollectionProps, ProductListingProps } from "../../util/types";
import ProductListingBlock from "../Elements/ProductListingBlock";
import { useAppSelector } from "../../store/hook";
import { RootState } from "../../store";
import getPriceInfo from "../../util/getPriceInfo";

const Cc06FeaturedCollection: FC<FeaturedCollectionProps> = ({ productListing }) => {
  const feed = useAppSelector((state: RootState) => state.feed.productInfo);
  const { locale } = useAppSelector((state: RootState) => state.site);
  const priceUpdatedProducts: ProductListingProps[] = getPriceInfo({ feed, productListing, locale });
  return (
    <div className="cpcs-esis-container">
      <div className="cpcs-esis_featured-collection-container">
        <ProductListingBlock products={priceUpdatedProducts} />
      </div>
    </div>
  );
};

export default Cc06FeaturedCollection;
