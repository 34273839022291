import parse from "html-react-parser";
import { StoryTelling } from "../../util/types";
import Button from "../Elements/Button";
import Section from "../Elements/Section";
import Image from "../Elements/Image";
import Description from "../Elements/Typography/Description";
import Title from "../Elements/Typography/Title";

const StoryMasterItem = ({
  productImgUrl,
  productTitle,
  productSubTitle,
  productDescription,
  productDisclaimer,
  buttons,
  titleSize,
  id,
}: StoryTelling) => {
  return (
    <div className={`cpcs-esis_story-telling-row `} key={id}>
      <div className="cpcs-esis_story-telling-col">
        <div className="cpcs-esis_story-telling-img">
          <Image src={productImgUrl} alt={productTitle} />
        </div>
      </div>
      <div className="cpcs-esis_story-telling-col">
        <div className="cpcs-esis_story-telling-content-area">
          <div className="cpcs-esis_story-telling-content-area-inner">
            <Title
              className="cpcs-esis_story-telling-content-title"
              size={titleSize}
              heading={productTitle}
            />
            {!productSubTitle ? (
              ""
            ) : (
              <div className="cpcs-esis_story-telling-content-subtitle">
                <p className="text-heavy text-lg">{parse(productSubTitle)}</p>
              </div>
            )}
            <Description
              className="cpcs-esis_story-telling-content-description"
              description={productDescription}
            />
            {!productDisclaimer ? (
              ""
            ) : (
              <div className="cpcs-esis_story-telling-content-disclaimer">
                <p className="text-sm text-gray">{productDisclaimer}</p>
              </div>
            )}
            {buttons.map((button) => (
              <div
                className="cpcs-esis_story-telling-content-cta"
                key={button.id}
              >
                <div className="cta-wrapper">
                  <Button
                    variant={button.ctaVariant}
                    color={button.ctaColor}
                    to={button.ctaURL}
                  >
                    {button.ctaText}
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const Cc07StoryTelling: React.FC<StoryTelling> = (storytellingData) => {
  return (
    <Section className="cpcs-esis_story-telling">
      <div className="cpcs-esis_story-telling-container">
        <div
          className={`cpcs-esis_story-telling-listing ${
            storytellingData.count === 1
              ? "cpcs-esis_story-telling-first-item"
              : ""
          }`}
        >
          <div className="cpcs-esis_story-telling-listing-inner">
            {<StoryMasterItem {...storytellingData} />}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Cc07StoryTelling;
