import { useState, useEffect } from "react";
import { AxiosResponse, AxiosError } from "axios";
import api from "../api";

const useApiHook = <T>(method: string, url: string, params: Record<string, any>, enabled: boolean = true) => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean | undefined>(undefined);
  const [error, setError] = useState<AxiosError<T> | null>(null);

  useEffect(() => {
    if (enabled && params && params.locale !== null) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const response: AxiosResponse<T> = await api(method, url, params);
          setData(response.data);
          setLoading(false);
          setError(null);
        } catch (error) {
          setError(error as AxiosError<T>);
          setLoading(false);
          setData(null);
        }
      };

      fetchData();
    }
  }, [params, url, enabled, method, setData, setError, setLoading]);

  return { data, loading, error };
};

export default useApiHook;
