import { FC } from "react";
import parse from "html-react-parser";

interface ProductTitleProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  size?: "large" | "medium" | "small";
}

const ProductTitle: FC<ProductTitleProps> = ({
  title,
  size = "large",
  ...rest
}) => {
  if (title) {
    return (
      <div {...rest}>
        {size === "large" && <h4>{parse(title)}</h4>}
        {size === "medium" && <h5>{parse(title)}</h5>}
        {size === "small" && <h6>{parse(title)}</h6>}
      </div>
    );
  }
  return <></>;
};

export default ProductTitle;
