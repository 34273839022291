import { FC } from "react";
import { SectionTitle } from "../../util/types";
import Title from "../Elements/Typography/Title";
import parse from "html-react-parser";

interface Cc13SectionTitleProps extends SectionTitle {}

const Cc13SectionTitle: FC<Cc13SectionTitleProps> = ({ id, noSubTitle, paddingBottom, paddingTop, subTitle, textColor, title, titleSize, backgroundColor }) => {
  return (
    <div className={`cpcs-esis-container cpcs-esis-section-title-bg-${backgroundColor}`} id={`cc13-section-title-${id}`}>
      <div className={`cpcs-esis-section-title-wrapper cpcs-esis-section-title-pt-${paddingTop}  cpcs-esis-section-title-pb-${paddingBottom}`}>
        <Title heading={title} color={textColor} size={titleSize} className={"cpcs-esis-section-title"} />
        {!noSubTitle && subTitle && (
          <div className="cpcs-esis-section-title-subtitle">
            <p>{parse(subTitle)}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cc13SectionTitle;
