/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";

const Container = css`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
`;

interface LoaderProps {}

export const Loader: React.FC<LoaderProps> = () => {
  return (
    <div css={Container}>
      <img src="https://media.samsungepartners.com/common/images/loader.gif" alt="Loader" />
    </div>
  );
};
