import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api";
import { setFooter, setHeader } from "../store/site";
import { AxiosResponse } from "axios";
import { footerParam, navParam } from "../util/constants";

export const fetchHeaderData = createAsyncThunk(
  "header/fetch",
  async (locale: string, thunkAPI) => {
    let params = { ...navParam, locale: locale };

    try {
      const response: AxiosResponse = await api(
        "GET",
        "api/header-navbar",
        params
      );
      if (
        response &&
        response.data &&
        response.data.attributes &&
        response.data.attributes.navigationData
      ) {
        return thunkAPI.dispatch(
          setHeader(response.data.attributes.navigationData)
        );
      }
    } catch (e) {
      console.log(e);
    }
  }
);

export const fetchFooterData = createAsyncThunk(
  "footer/fetch",
  async (locale: string, thunkAPI) => {
    let params = { ...footerParam, locale: locale };
    try {
      const response: AxiosResponse = await api(
        "GET",
        "api/footer-navbar",
        params
      );
      if (
        response &&
        response.data &&
        response.data.attributes &&
        response.data.attributes.categoryFooter
      ) {
        let categoryFooter = {
          headline: response.data.attributes.headline,
          footer: response.data.attributes.categoryFooter,
        };
        return thunkAPI.dispatch(setFooter(categoryFooter));
      }
    } catch (e) {
      console.log(e);
    }
  }
);
