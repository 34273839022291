import { FC, useMemo } from "react";
import Section from "./Section";
import { AxiosError } from "axios";

interface ErrorPageProps {
  error?:
    | {
        status?: string;
        message: string;
      }
    | AxiosError
    | null;
}

const ErrorPage: FC<ErrorPageProps> = ({ error }) => {
  const errMsg = useMemo(() => {
    if (error && error.message) {
      return error.message;
    }
    return "Something went wrong";
  }, [error]);

  const refreshPage = () => window.location.reload();

  return (
    <Section>
      <div className="img-wrapper">
        <img
          src="https://images.samsung.com/is/image/samsung/assets/ae/common/gnusmas_pageNotFound.jpg?$360_360_JPG$"
          alt="Error-page"
        />
      </div>
      <h4>Ooops, {errMsg}</h4>
      <div className="py-md-dkp">
        <p className="text-sm text-gray" dir="ltr">
          {" "}
          Please try again.....
        </p>
        <a
          className={`cta cta--underlined cta--black cta--arrow`}
          onClick={refreshPage}
        >
          <p dir="ltr">Reload</p>
        </a>
      </div>
    </Section>
  );
};

export default ErrorPage;
