import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  productInfo: any[] | null;
}
const slice = createSlice({
  name: "feed",
  initialState: {
    productInfo: null,
  } as InitialState,
  reducers: {
    setFeed: (state: InitialState, action: { type: string; payload: any[] }) => {
      state.productInfo = action.payload;
    },
  },
});

export default slice.reducer;
export const { setFeed } = slice.actions;
