import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { RootState } from "../../store";
import { fetchHeaderData } from "../../dao/common";
import NavigationBar from "../Elements/NavBar";

const Cc12NavBar = () => {
  const dispatch = useAppDispatch();
  const { navigationData, locale } = useAppSelector(
    (state: RootState) => state.site
  );

  useEffect(() => {
    if (locale) {
      dispatch(fetchHeaderData(locale));
    }
  }, [dispatch, locale]);

  if (navigationData && navigationData !== null) {
    return <NavigationBar headerData={navigationData} />;
  }
  return null;
};

export default Cc12NavBar;
