/** @jsxImportSource @emotion/react */
import { FC } from "react";
import { Cc15BannerCategorySeriesProps } from "../../util/types";
import Banner from "../Elements/Banner";
import { css } from "@emotion/react";

const bigBannerLeft = css`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
`;
const bigBannerRight = css`
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
`;

const Cc15BannerCategorySeries: FC<Cc15BannerCategorySeriesProps> = ({ bannerHalf, bannerSquare, bannerSquarePosition }) => {
  return (
    <div className="cpcs-esis-container">
      <div className="cpcs-esis_banner_half_tiles_wrapper">
        <div className="cpcs-esis_banner-product_big-half_tile" css={bannerSquarePosition === "right" ? bigBannerRight : bigBannerLeft}>
          <Banner {...bannerSquare} />
        </div>

        {bannerHalf.map((item, key) => (
          <div key={key} className="cpcs-esis_banner-product_small-half_tile">
            <Banner {...item} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cc15BannerCategorySeries;
