

export const getPCOverlayClass = (clsName: string |null) => {
  if (clsName === null) return ""
  switch (clsName) {
    case "TopLeft":
      return "cpcs-esis_overlay-top-left";
    case "TopCenter":
      return "cpcs-esis_overlay-top-center";
    case "TopRight":
      return "cpcs-esis_overlay-top-right";
    case "MiddleLeft":
      return "cpcs-esis_overlay-middle-left";
    case "MiddleCenter":
      return "cpcs-esis_overlay-middle-center";
    case "MiddleRight":
      return "cpcs-esis_overlay-middle-right";
    case "BottomLeft":
      return "cpcs-esis_overlay-bottom-left";
    case "BottomCenter":
      return "cpcs-esis_overlay-bottom-center";
    case "BottomRight":
      return "cpcs-esis_overlay-bottom-right";
    default:
       return
  }
}