/** @jsxImportSource @emotion/react */
import { FC } from "react";
import parse from "html-react-parser";
import { css } from "@emotion/react";

const Styles = (color: string) => css`
  color: ${color};
`;
interface TitleProps extends React.HTMLAttributes<HTMLDivElement> {
  heading?: string | null;
  size?: "large" | "medium" | "small";
  color?: string;
}

const Title: FC<TitleProps> = ({ heading, size = "large", color = "black", ...rest }) => {
  if (heading) {
    return (
      <div {...rest}>
        {size === "large" && <h2 css={Styles(color)}>{parse(heading)}</h2>}
        {size === "medium" && <h3 css={Styles(color)}>{parse(heading)}</h3>}
        {size === "small" && <h4 css={Styles(color)}>{parse(heading)}</h4>}
      </div>
    );
  }
  return <></>;
};

export default Title;
