import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { RootState } from "../../store";
import Cc05CatagoriesFooter from "../../components/Modules/Cc05CatagoriesFooter";
import { fetchFooterData } from "../../dao/common";
import RenderComponents from "../../components/Elements/RenderComponents";
import { gcParams } from "../../util/constants";
import { ResponseData } from "../../util/types";
import useApiHook from "../../hooks/useApiHook";
import { Loader } from "../../components/Loader";
import ErrorPage from "../../components/Elements/ErrorPage";
import Cc12NavBar from "../../components/Modules/Cc12NavBar";
import PageTitle from "../../components/Elements/PageTitle";

const GroupCategoryPage = () => {
  const dispatch = useAppDispatch();
  const { groupCategorySlug } = useParams();
  const { categoryFooter, locale } = useAppSelector((state: RootState) => state.site);
  const params = useMemo(() => {
    return { ...gcParams, locale: locale };
  }, [locale]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [groupCategorySlug]);
  const { data, error, loading } = useApiHook<ResponseData>("GET", "api/group-categories/" + groupCategorySlug, params, Boolean(groupCategorySlug));

  useEffect(() => {
    if (locale) {
      dispatch(fetchFooterData(locale));
    }
  }, [dispatch, locale]);

  if (loading) return <Loader />;
  if (error) return <ErrorPage error={error} />;
  if (loading !== undefined && data === null) {
    return <ErrorPage error={{ message: " Data not Found " }} />;
  }

  return (
    <div>
      {data && data.attributes && data.attributes.pageName && (
        <PageTitle>
          <h2>{data.attributes.pageName}</h2>
        </PageTitle>
      )}
      <Cc12NavBar />
      {data && data.attributes && data.attributes.groupCategory && <RenderComponents data={data.attributes.groupCategory} />}
      {categoryFooter && categoryFooter !== null && <Cc05CatagoriesFooter {...categoryFooter} />}
    </div>
  );
};
export default GroupCategoryPage;
