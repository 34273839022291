import { FC } from "react";
import { BlankSpaceProps } from "../../util/types";

const Cc20BlackSpace: FC<BlankSpaceProps> = ({ sectionId, backgroundColor, desktopMargin, mobileMargin }) => {
  return (
    <div className="cpcs-esis-blank-space">
      <div
        id={sectionId}
        className={`cpcs-esis-blank-space-dpk-${desktopMargin} cpcs-esis-blank-space-mbl-${mobileMargin} cpcs-esis-blank-space-bg-${backgroundColor}`}
      />
    </div>
  );
};

export default Cc20BlackSpace;
