import { ARLangs, ENLangs } from "./constants";

export const findLanguage = (url: string) => {
  const urlSplits = url.split("/");
  const arMatches = ARLangs.some((element) => urlSplits.includes(element));
  if (arMatches) return "ar";
  const enMatches = ENLangs.some((element) => urlSplits.includes(element));
  if (enMatches) return "en";
  return "en";
};
