import { modules } from "../../util/constants";
import Cc01MainKvs from "../Modules/Cc01MainKvs";
import Cc02IntroHeader from "../Modules/Cc02IntroHeader";
import Cc06FeaturedCollection from "../Modules/Cc06FeaturedCollection";
import Cc04ShopBySelector from "../Modules/Cc04ShopBySelector";
import Cc07StoryTelling from "../Modules/Cc07StoryTelling";
import Cc08BuyTogether from "../Modules/Cc08BuyTogether";
import Cc09ReviewSection from "../Modules/Cc09ReviewSection";

import ErrorPage from "./ErrorPage";
import Cc03CatagoriesMaster from "../Modules/Cc03CatagoriesMaster";
import Cc10HomeGeneralKvs from "../Modules/Cc10HomeGeneralKvs";
import Cc11ShowCaseLargeCards from "../Modules/Cc11ShowCaseLargeCards";
import Cc16ShowCaseProductCards from "../Modules/Cc16ShowCaseProductCards";
import Cc17ShowCaseBannerCards from "../Modules/Cc17ShowCaseBannerCards";
import Cc13SectionTitle from "../Modules/Cc13SectionTitle";
import Cc14BannerProduct from "../Modules/Cc14BannerProduct";
import Cc15BannerCategorySeries from "../Modules/Cc15BannerCategorySeries";
import Cc19FeatureStorySquare from "../Modules/Cc19FeatureStorySquare";
import Cc20BlackSpace from "../Modules/Cc20BlackSpace";

const RenderComponents: any = ({ data }: { data: Array<any> }) => {
  if (data && data.length > 0) {
    let count = 0;
    const getId = (sectionId: string | null) => {
      let uniqueTimeStamp = new Date().valueOf();
      return `${sectionId}-${uniqueTimeStamp}`;
    };
    return data.map((componentData, index) => {
      switch (componentData.__component) {
        case modules.KV_CAROUSEL:
          return <Cc01MainKvs {...componentData} key={getId(componentData.sectionId) + index} />;
        case modules.INTRO_SECTION:
          return <Cc02IntroHeader {...componentData} key={getId(componentData.sectionId)} />;
        case modules.FEATURED_COLLECTIONS:
          return <Cc06FeaturedCollection {...componentData} key={getId(componentData.sectionId)} />;
        case modules.SHOP_BY_SELECTOR:
          return <Cc04ShopBySelector {...componentData} key={getId(componentData.sectionId)} />;
        case modules.STORY_TELLING:
          count = count + 1;
          return <Cc07StoryTelling {...componentData} key={getId(componentData.sectionId)} count={count} />;
        case modules.BUNDLE_COLLECTION:
          return <Cc08BuyTogether {...componentData} key={getId(componentData.sectionId)} />;
        case modules.REVIEW_SECTION:
          return <Cc09ReviewSection {...componentData} key={getId(componentData.sectionId)} />;
        case modules.CATEGORIES_MASTER:
          return <Cc03CatagoriesMaster {...componentData} key={getId(componentData.sectionId)} />;
        case modules.HOME_CATEGORY_KV:
          return <Cc10HomeGeneralKvs {...componentData} key={getId(componentData.sectionId)} />;
        case modules.SHOW_CASE_LARGE_CARDS:
          return <Cc11ShowCaseLargeCards {...componentData} key={getId(componentData.sectionId)} />;
        case modules.SHOW_CASE_PRODUCT_CARDS:
          return <Cc16ShowCaseProductCards {...componentData} key={getId(componentData.sectionId)} />;
        case modules.SHOW_CASE_BANNER_CARDS:
          return <Cc17ShowCaseBannerCards {...componentData} key={getId(componentData.sectionId)} />;
        case modules.SECTION_TITLE:
          return <Cc13SectionTitle {...componentData} key={getId(componentData.sectionId)} />;
        case modules.BANNER_PRODUCT:
          return <Cc14BannerProduct {...componentData} key={getId(componentData.sectionId)} />;
        case modules.HALF_BANNER_TILES:
          return <Cc15BannerCategorySeries {...componentData} key={getId(componentData.sectionId)} />;
        case modules.FEATURE_STORY_SQUARE:
          return <Cc19FeatureStorySquare {...componentData} key={getId(componentData.sectionId)} />;
        case modules.BLANK_SPACE:
          return <Cc20BlackSpace {...componentData} key={getId(componentData.sectionId)} />;
        default:
          return null;
      }
    });
  }
  return <ErrorPage />;
};

export default RenderComponents;
