import * as React from 'react';
import { BuyTogether } from '../../util/types';
import BuyTogetherBanner from '../Elements/BuyTogetherBanner';
import Section from '../Elements/Section';
import ProductItem from '../Elements/ProductItem';

const Cc08BuyTogether: React.FC<BuyTogether> = (data) => {
  return (<>
    <Section className='bg-off-white'>
      <div className="cmp-collection-text-img">
        <div className="cmp-collection-text-img-inner">
          <BuyTogetherBanner {...data} />
          <div className='cpcs-esis_collection-bundle-container'>
            <div className='cpcs-esis_collection-bundle-listing-inner'>
              {data.productListing && data.productListing.length > 0 &&
                data.productListing.map((product) => (
                  <ProductItem
                    key={product.id}
                    productImgUrl={product.productImgUrl}
                    productTitle={product.productTitle}
                    ctaText={product.ctaText}
                    titleSize={product.titleSize}
                    ctaURL={product.ctaURL} />
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </Section>
  </>);
}

export default Cc08BuyTogether;