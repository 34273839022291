/** @jsxImportSource @emotion/react */
import { FC, useMemo } from "react";
import { CSSObject, css } from "@emotion/react";
import { BuyTogether } from "../../util/types";
import Image from "./Image";
import Title from "./Typography/Title";
import Description from "./Typography/Description";
import { getPCOverlayClass } from "../../util/getPcOverlayClass";
import { getMOverlayClass } from "../../util/getMoOverlayClass";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const PositionStyles = (desktopTextPositionJson: CSSObject | null, mobileTextPositionJson: CSSObject | null) => css`
  @media (min-width: 768px) {
    [dir="ltr"] & {
      ${desktopTextPositionJson}
    }
    [dir="rtl"] & {
      ${desktopTextPositionJson}
    }
  }
  @media (max-width: 767px) {
    [dir="ltr"] & {
      ${mobileTextPositionJson}
    }
    [dir="rtl"] & {
      ${mobileTextPositionJson}
    }
  }
`;
const BuyTogetherBanner: FC<BuyTogether> = (props) => {
  const {
    backgroundImgUrl,
    backgroundAltText,
    description,
    desktopTextPositionJson,
    mobileTextPositionJson,
    positionMoCss,
    positionPcCss,
    title,
    textColor,
    titleSize,
    descriptionSize,
  } = props;
  const locale = useSelector((state: RootState) => state.site.locale);

  const pcStyles = useMemo(() => {
    return getPCOverlayClass(positionPcCss);
  }, [positionPcCss]);

  const moStyles = useMemo(() => {
    return getMOverlayClass(positionMoCss);
  }, [positionMoCss]);

  return (
    <div className="cpcs-esis_collection-text-img-row">
      <div className="cpcs-esis_collection-bundle-welcome">
        <div
          className={`cpcs-esis_collection-bundle-welcome-overlay ${pcStyles} ${moStyles}`}
          css={PositionStyles(desktopTextPositionJson, mobileTextPositionJson)}
          dir={locale === "ar" ? "rtl" : "ltr"}
        >
          <div className="cpcs-esis_collection-bundle-welcome-overlay-content">
            <Title className="cpcs-esis_overlay-content-title" heading={title} color={textColor} size={titleSize} />
            <Description description={description} className="cpcs-esis_overlay-content-desc" color={textColor} size={descriptionSize} />
          </div>
        </div>
        <div className="cpcs-esis_collection-bundle-welcome-bg desktop-only">
          <Image src={backgroundImgUrl} alt={backgroundAltText} />
        </div>
      </div>
    </div>
  );
};

export default BuyTogetherBanner;
