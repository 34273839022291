import { useEffect } from "react";

import { Outlet } from "react-router";
import { Suspense } from "react";
import { Loader } from "../Loader";

const MainLayout: any = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="cpcs-esis-wrapper">
      <div className="cpcs-esis-container">
        <div className="cpcs-esis-container-wrapper">
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
