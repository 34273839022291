import { FC } from "react";

interface PageTitleProps {
  children: React.ReactNode | JSX.Element;
}

const PageTitle: FC<PageTitleProps> = ({ children }) => {
  return (
    <div className="cpcs-esis-container bg-off-white">
      <div className="cpcs-esis-section-title-inner">{children}</div>
    </div>
  );
};

export default PageTitle;
