/** @jsxImportSource @emotion/react */
import { FC, useMemo } from "react";
import { Cc14BannerProductProps, TextSize } from "../../util/types";
import { css } from "@emotion/react";
import Banner from "../Elements/Banner";
import SmallBannerProductBanner from "../Elements/SmallBannerProductBanner";

const bigBannerLeft = css`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
`;
const bigBannerRight = css`
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
`;
const gridRight = css`
  grid-template-columns: 28.57% 70.63%;
  // grid-template-columns: 33.06% 66.137%;
`;
const gridLeft = css`
  grid-template-columns: 70.63% 28.57%;
  // grid-template-columns: 66.137% 33.06%;
`;
const Cc14BannerProduct: FC<Cc14BannerProductProps> = ({ bannerHalf, bannerRect, bannerRectPosition }) => {
  let updatedBannerRect = useMemo(() => {
    return { ...bannerRect, titleSize: "medium" as TextSize };
  }, [bannerRect]);
  let updatedBannerHalf = useMemo(() => {
    if (bannerHalf) {
      return bannerHalf.map((item) => {
        item.titleSize = "small";
        return item;
      });
    }
    return;
  }, [bannerHalf]);

  return (
    <div className="cpcs-esis-container">
      <div className="cpcs-esis_banner-product_container" css={bannerRectPosition === "right" ? gridRight : gridLeft}>
        <div className="cpcs-esis_banner-product_big_tile" css={bannerRectPosition === "right" ? bigBannerRight : bigBannerLeft}>
          <Banner {...updatedBannerRect} />
        </div>
        {updatedBannerHalf &&
          updatedBannerHalf.map((item, index) => (
            <div className="cpcs-esis_banner-product_small_tile" key={index}>
              <SmallBannerProductBanner {...item} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Cc14BannerProduct;
